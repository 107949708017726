// Assuming you have imported React at the top of your file
import React from 'react';

// Define the props interface for Login component
interface LoginProps {
  authService: {
    login: () => Promise<void>;
  };
}

// Define the Login component using functional component syntax
const Login: React.FC<LoginProps> = (props) => {
  return (
    <button onClick={() => props.authService.login()}>
      Login
    </button>
  );
};

export default Login;
