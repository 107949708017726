import * as React from "react";
import { IAuthService } from "../services/authService";
import { Link, useNavigate } from "react-router-dom";

interface ISignin {
  authService: IAuthService;
}

const SigninCallback: React.FC<ISignin> = (props) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      try {
        const token = await props.authService.signinCallback();
        localStorage.setItem("token", JSON.stringify(token));
      } catch (e) {
        console.error(e);
      } finally {
        navigate("/edit-profile/basic");
      }
    })();
  });

  return (
    <div className="pt-5 pb-2 mt-5">
      <main className="px-3 pt-5 text-center">
        <h3>Welcome! You're Successfully Logged In</h3>
        <p className="lead">
          If you're not automatically redirected, please click the button below
          to explore your profile.
        </p>
        <p className="mb-0">
          <Link to="/edit-profile/basic" className="btn btn-primary">
            Explore Profile
          </Link>
        </p>
      </main>
    </div>
  );
};

export default SigninCallback;
