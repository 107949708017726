import { Link } from "react-router-dom";
import logo from "../assets/img/placeholder-logo.png";

function Cards(companyData: any) {
  const { id, name, profilePictureUrl, isAdd, url, image } =
    companyData.companyData;
  const comanyLogo = profilePictureUrl
    ? profilePictureUrl.includes("Uploads")
      ? `${profilePictureUrl}`
      : profilePictureUrl
    : logo;
  if (!companyData) {
    return <p>Loading...</p>;
  } else if (companyData.error) {
    return <p>Error: {companyData.error}</p>;
  } else {
    return isAdd ? (
      <a className="featuredAdd" href={url} target="_blank">
        <img src={image} alt="" />
      </a>
    ) : (
      <div className="card featured-card h-100 text-center">
        <Link to={"/company/" + id} className="company-logo stretched-link">
          <img src={comanyLogo} alt="" />
        </Link>
        <div className="card-body pb-0">
          <h5 className="card-title text-truncate px-3">{name}</h5>
        </div>
      </div>
    );
  }
}

export default Cards;
