import "./Footer.scss";
import logo from "./../../assets/img/logo-colored.svg";
import { NavLink } from "react-router-dom";
function Footer() {
  return (
    <>
      <footer className="mt-5">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-5 align-items-start">
            <div className="col my-2">
              <NavLink
                to="/"
                className="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none"
              >
                <img src={logo} className="footer-logo mw-100" alt="" />
              </NavLink>
            </div>

            <div className="col my-2"></div>

            <div className="col my-2">
              <h5>JOIN THE COMMUNITY</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <NavLink
                    to="/contact?contactType=advertize"
                    className="nav-link d-inline-block p-0"
                  >
                    Advertisers
                  </NavLink>
                </li>
                <li className="nav-item mb-0">
                  <NavLink
                    to="/companies"
                    className="nav-link d-inline-block p-0"
                  >
                    Companies
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="col my-2">
              <h5>
                Who is <span className="text-primary">ALVA</span>
              </h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <NavLink
                    to="https://www.linkedin.com/company/askalvaofficial"
                    target="_blank"
                    className="nav-link d-inline-block p-0"
                  >
                    LinkedIn
                  </NavLink>
                </li>
                <li className="nav-item mb-0">
                  <NavLink
                    to="/contact"
                    className="nav-link d-inline-block p-0"
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="col my-2">
              <h5>OTHER STUFF</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <NavLink
                    to="/privacy-policy"
                    className="nav-link d-inline-block p-0"
                  >
                    Privacy
                  </NavLink>
                </li>
                <li className="nav-item mb-0">
                  <NavLink
                    to="/terms-conditions"
                    className="nav-link d-inline-block p-0"
                  >
                    Terms of use
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
