import React, { useEffect, useState } from "react";
import { apiService } from "../../../services/apiService";
import Company from "../../../Models/Company";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "../EditProfile.scss";
import PageLoader from "../../../shared/page-loader";

interface POCFormProps {
  companyId: null | string;
}

const POC: React.FC<POCFormProps> = (companyId) => {
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [formSubmission, setFormSubmission] = useState(true);
  const [userCompany, setUserCompany] = useState<Company | null>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState<null | "success">(
    null
  );
  const [errors, setErrors] = useState<{
    name?: string;
    email?: string;
    phone?: string;
  }>({});
  const id = companyId.companyId;
  const webLocation = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [webLocation]);

  useEffect(() => {
    if (id !== "" || null) {
      const endpoint = `Company/${id}`;
      apiService
        .get(endpoint)
        .then((response) => {
          setUserCompany(response);
          setName(response.contactPersonName);
          setEmail(response.contactEmail);
          setPhone(response.contactPhoneMumber);
          setLoading(false);
          setPageLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          setTimeout(() => {
            setPageLoading(false);
          }, 1000);
        });
    } else {
      setLoading(false);
    }
  }, [companyId]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const formData = {
        id: id,
        requestUserId: id,
        contactEmail: email ? email : "",
        contactPersonName: name ? name : "",
        contactPhoneMumber: phone ? phone : "",
      };
      try {
        setFormSubmission(false);
        setTimeout(async () => {
          const endpoint = `Company`;
          const response = await apiService.put(endpoint, formData);
          setFormSubmission(true);
          toast.success("Updated successfully!");
        }, 1000); // 1000 milliseconds (1 second)
      } catch (error) {
        // Handle error if needed
        toast.error("Updating failed!");
        setFormSubmission(true);
      }
    }
  };

  const validateForm = () => {
    let errors: {
      name?: string;
      email?: string;
      phone?: string;
    } = {};
    // Validate name field
    if (!name.trim()) {
      errors.name = "Name is required";
    }
    // Validate email field
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format";
    }
    // Validate phone field
    if (!phone.trim()) {
      errors.phone = "Phone is required";
    } else if (!/^\d{10}$/.test(phone)) {
      // Example: 10 digits phone number
      errors.phone = "Invalid phone number format";
    }
    return errors;
  };

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="row g-4">
        <div className="col-md-6">
          <label htmlFor="name" className="form-label ms-4">
            Name
          </label>
          <input
            type="text"
            id="name"
            className={`form-control ${errors.name ? "is-invalid" : ""}`}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            disabled={!userCompany}
          />
          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="email" className="form-label ms-4">
            Email
          </label>
          <input
            type="email"
            id="email"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            disabled={!userCompany}
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email}</div>
          )}
        </div>
        <div className="col-md-6">
          <label htmlFor="phone" className="form-label ms-4">
            Phone
          </label>
          <input
            type="tel"
            id="phone"
            className={`form-control ${errors.phone ? "is-invalid" : ""}`}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone"
            disabled={!userCompany}
          />
          {errors.phone && (
            <div className="invalid-feedback">{errors.phone}</div>
          )}
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-5">
          {formSubmission ? (
            <button className="btn btn-primary" type="submit">
              Save changes
            </button>
          ) : (
            <button className="btn btn-primary" type="button" disabled>
              <span
                className="spinner-grow spinner-grow-sm"
                aria-hidden="true"
              ></span>
              <span className="ms-2" role="status">
                Saving
              </span>
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default POC;
