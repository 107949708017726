import "./EditProfile.scss";
import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { User } from "oidc-client";
import Company from "../../Models/Company";
import Basics from "./Components/Basics";
import Security from "./Components/Security";
import Services from "./Components/Services";
import Technologies from "./Components/Technologies";
import POC from "./Components/POC";
import Locations from "./Components/Locations";
import Aside from "./Components/Aside";
import References from "./Components/References";

interface ProfileProps {
  authService: {
    login: () => Promise<void>;
    logout: () => Promise<void>;
    getUser: () => Promise<User | null>;
  };
}
interface RenderRouteContentProps {
  companyId: string;
}
const RenderRouteContent: React.FC<RenderRouteContentProps> = ({
  companyId,
}) => {
  return (
    <div className="card border-0">
      <div className="card-body p-4">
        {companyId ? (
          <Routes>
            <Route path="/" element={<Outlet />}>
              <Route path="basic" element={<Basics companyId={companyId} />} />
              <Route
                path="services"
                element={<Services companyId={companyId} />}
              />
              <Route
                path="technologies"
                element={<Technologies companyId={companyId} />}
              />
              <Route path="poc" element={<POC companyId={companyId} />} />
              <Route
                path="references"
                element={<References companyId={companyId} />}
              />
              <Route path="locations" element={<Locations />} />
              <Route path="password" element={<Security />} />
              <Route index element={<Navigate to="basic" />} />
            </Route>
          </Routes>
        ) : (
          <p className="lead">Loading ...</p>
        )}
      </div>
    </div>
  );
};

const EditProfile: React.FC<ProfileProps> = (props) => {
  const [user, setUser] = useState<User | null>();
  const [companyId, setCompanyId] = useState("");
  const [userCompany, setUserCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const serviceGetUser = async () => {
      const userResponse = await props.authService.getUser();
      if (userResponse) {
        setCompanyId(userResponse.profile.sub);
      } else {
        //navigate('/');
      }
      setUser(userResponse);
      return userResponse;
    };

    serviceGetUser();
  }, [props.authService, navigate]);

  useEffect(() => {
    const handleResize = () => {
      const isMobileQuery = window.matchMedia("(max-width: 991px)").matches;
      setIsMobile(isMobileQuery);
    };

    // Initial check on mount
    handleResize();

    // Add event listener for changes in screen size
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isBasicPage = location.pathname === "/edit-profile/basic";
  const isServicesPage = location.pathname === "/edit-profile/services";
  const isTechnologiesPage = location.pathname === "/edit-profile/technologies";
  const isPocPage = location.pathname === "/edit-profile/poc";
  const isReferencesPage = location.pathname === "/edit-profile/references";
  return (
    <>
      <div className="container py-5 mt-4">
        <div className="mb-4 pt-1">
          <div className="mt-4 pt-2 text-end">
            <NavLink
              to={"/company/" + companyId}
              className="btn btn-outline-primary btn-sm"
            >
              Preview company
            </NavLink>
          </div>
        </div>
        {isMobile ? (
          <aside className="d-block d-lg-none">
            <div className="list-group list-group-flush">
              <NavLink
                to="/edit-profile/basic"
                className="list-group-item list-group-item-action"
              >
                Basics
              </NavLink>
              {isBasicPage && <RenderRouteContent companyId={companyId} />}

              <NavLink
                to="/edit-profile/services"
                className="list-group-item list-group-item-action"
              >
                Services
              </NavLink>
              {isServicesPage && <RenderRouteContent companyId={companyId} />}
              <NavLink
                to="/edit-profile/technologies"
                className="list-group-item list-group-item-action"
              >
                Technologies
              </NavLink>
              {isTechnologiesPage && (
                <RenderRouteContent companyId={companyId} />
              )}
              <NavLink
                to="/edit-profile/poc"
                className="list-group-item list-group-item-action"
              >
                Contact Person
              </NavLink>
              {isPocPage && <RenderRouteContent companyId={companyId} />}
              <NavLink
                to="/edit-profile/references"
                className="list-group-item list-group-item-action"
              >
                References
              </NavLink>
              {isReferencesPage && <RenderRouteContent companyId={companyId} />}
            </div>
          </aside>
        ) : (
          <div className="d-none d-lg-block">
            <div className="row g-3">
              <div className="col-lg-3 col-md-4">
                <Aside />
              </div>
              <div className="col-lg-9 col-md-8">
                <RenderRouteContent companyId={companyId} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditProfile;
