import React, { useState, useEffect, useRef } from "react";
import { apiService } from "../../services/apiService";
import Company from "../../Models/Company";
import CompanyResponse from "../../Models/CompanyResponse";
import SearchState from "../../Models/SearchState";
import { useLocation } from "react-router-dom";
import "./Other.scss";
import SearchBanner from "../../components/Banner/SearchBanner";
import Cards from "../../shared/Cards";
import Spinner from "../../shared/Spinner";
import PageLoader from "./../../shared/page-loader";

const Companies: React.FC = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [state, setState] = useState<SearchState>({
    companyData: {} as CompanyResponse,
    isLoading: true,
    pageNumber: 1,
    PageSize: 300,
    OrderByColumn: "date",
    OrderBy: "ASC",
  });

  interface AlphaCompanyList {
    alphabet: string;
    items: Company[];
  }

  const [alphabeticalCompanies, setAlphabeticalCompanies] = useState<
    AlphaCompanyList[]
  >([]);
  const [moreCompanies, setMoreCompanies] = useState(true);
  const alphabetRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const endpoint = `Company?PageNumber=1&PageSize=${state.PageSize}&OrderByColumn=${state.OrderByColumn}&OrderBy=${state.OrderBy}`;

  const sortCompaniesAlphabetically = (
    companies: Company[]
  ): AlphaCompanyList[] => {
    const groupedCompanies: { [key: string]: Company[] } = {};

    companies.forEach((company) => {
      const firstLetter = company.name[0].toLowerCase();
      if (!groupedCompanies[firstLetter]) {
        groupedCompanies[firstLetter] = [];
      }
      groupedCompanies[firstLetter].push(company);
    });

    const alphabeticalList: AlphaCompanyList[] = Object.keys(groupedCompanies)
      .sort()
      .map((letter) => ({
        alphabet: letter.toUpperCase(),
        items: groupedCompanies[letter],
      }));

    return alphabeticalList;
  };

  useEffect(() => {
    apiService
      .get(endpoint)
      .then((response) => {
        const sortedCompanyData = response.items.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        );

        const alphabeticalData = sortCompaniesAlphabetically(sortedCompanyData);

        setState((prevState) => ({
          ...prevState,
          companyData: { ...response, items: sortedCompanyData }, // Update the items array
          isLoading: false,
          pageNumber: 1,
        }));

        setAlphabeticalCompanies(alphabeticalData);

        setPageLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setPageLoading(false);
        }, 1000);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  }, []);

  const handleLoadMore = () => {
    const newPageNumber = state.pageNumber + 1;
    const newEndpoint = `Company?PageNumber=${newPageNumber}&PageSize=${state.PageSize}&OrderByColumn=${state.OrderByColumn}&OrderBy=${state.OrderBy}`;
    apiService
      .get(newEndpoint)
      .then((response) => {
        setMoreCompanies(false);
        setTimeout(() => {
          const updatedItems = [...state.companyData.items, ...response.items];
          const sortedCompanyData = updatedItems.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );

          const alphabeticalData =
            sortCompaniesAlphabetically(sortedCompanyData);

          setState((prevState) => ({
            ...prevState,
            companyData: {
              ...prevState.companyData,
              items: sortedCompanyData,
              pageNumber: response.pageNumber,
              totalPages: response.totalPages,
              totalCount: response.totalCount,
              hasPreviousPage: response.hasPreviousPage,
              hasNextPage: response.hasNextPage,
            },
            pageNumber: newPageNumber,
          }));

          setAlphabeticalCompanies(alphabeticalData);
          setMoreCompanies(true);
        }, 1000); // 1000 milliseconds (1 second)
      })
      .catch((error) => {
        console.error(error);
        setMoreCompanies(true);
      });
  };

  const webLocation = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [webLocation]);

  const handleAlphabetClick = (alphabet: string) => {
    const ref = alphabetRefs.current[alphabet];
    if (ref) {
      const topOffset =
        ref.getBoundingClientRect().top + window.pageYOffset - 80;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <SearchBanner />
      <main className="OtherPage container py-3">
        {state.isLoading ? (
          <Spinner />
        ) : (
          <>
            <h3>Companies</h3>
            <div className="alphabet-nav">
              {alphabeticalCompanies.map((group) => (
                <button
                  onClick={() => handleAlphabetClick(group.alphabet)}
                  key={group.alphabet}
                  type="button"
                  className="btn btn-outline-primary btn-sm me-2"
                >
                  {group.alphabet}
                </button>
              ))}
            </div>
            {alphabeticalCompanies.length > 0 ? (
              alphabeticalCompanies.map((group) => (
                <div
                  className="pt-3"
                  key={group.alphabet}
                  ref={(el) => (alphabetRefs.current[group.alphabet] = el)}
                >
                  <h4>{group.alphabet}</h4>
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-6 g-3">
                    {group.items.map((company) => (
                      <div className="col" key={company.id}>
                        <Cards companyData={company} />
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <p>No company found</p>
            )}
            {state.companyData.hasNextPage && (
              <div className="d-grid gap-2 d-flex justify-content-center mt-5">
                {moreCompanies ? (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleLoadMore}
                  >
                    Show More
                  </button>
                ) : (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      aria-hidden="true"
                    ></span>
                    <span className="ms-2" role="status">
                      Loading
                    </span>
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </main>
    </>
  );
};

export default Companies;
