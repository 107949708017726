import "./UnderConstruction.scss";
import comingSoonImg from "./../../assets/img/coming-soon.png";
function UnderConstruction() {
  return (
    <div className="container pt-5">
      <div className="px-3 py-5 mt-5 text-center comingSoon">
        <img src={comingSoonImg} alt="Coming Soon" className="img-fluid" />
      </div>
    </div>
  );
}

export default UnderConstruction;
