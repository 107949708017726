import { useState } from "react";
import { Routes, NavLink, Route, Outlet, Navigate } from "react-router-dom";
import SignupForm from "./SignupForm/SignupForm";
import Services from "./Services/Services";
import CompanyLogo from "./CompanyLogo/CompanyLogo";
import AccountCreated from "./AccountCreated/AccountCreated";
import Logo from "./../../assets/img/logo-colored.svg";
import "./Signup.scss";

interface AuthProps {
  authService: {
    login: () => Promise<void>;
    logout: () => Promise<void>;
  };
}
const Signup: React.FC<AuthProps> = (props) => {
  const [isCompanySignup, setIsCompanySignup] = useState<boolean>(true);

  const handleTabChange = (isCompany: boolean) => {
    setIsCompanySignup(isCompany);
  };

  return (
    <div className="container-fluid onboarding">
      <div className="row">
        <div className="col-md-6 logo-section">
          <NavLink to="/">
            <img src={Logo} alt="" className="align-middle d-inline-block" />
          </NavLink>
        </div>
        <div className="col-md-6 col-lg-5 col-xl-4">
          <div className="content p-5">
            <Routes>
              <Route path="/" element={<Outlet />}>
                <Route
                  path="register"
                  element={<SignupForm authService={props.authService} />}
                />
                <Route path="services" element={<Services />} />
                <Route
                  path="account-created"
                  element={<AccountCreated authService={props.authService} />}
                />
                <Route index element={<Navigate to="register" />} />
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
