import axios from "axios";

// const API_URL = process.env.REACT_APP_API_URL;
// const IDENTITY_URL = process.env.REACT_APP_IDENTITY_URL;;

// Function to get the access token (this might be from local storage, a Redux store, a context, etc.)
const getAccessToken = () => {
  // Example: retrieving from local storage
  let token: any = "";
  const tokenString = localStorage.getItem("token");
  if (tokenString !== null) {
    token = JSON.parse(tokenString);
  }
  return token;
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to dynamically set the Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      //config.headers['Authorization'] = `Bearer ${token.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const identityInstance = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ` + getAccessToken() || "",
  },
});

export const apiService = {
  async get(endpoint: string) {
    try {
      const response = await axiosInstance.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async post(endpoint: string, data: any) {
    try {
      const response = await axiosInstance.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async put(endpoint: string, data: any) {
    try {
      const response = await axiosInstance.put(endpoint, data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async delete(endpoint: string, data: any) {
    try {
      const response = await axiosInstance.delete(endpoint, { data });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async deleteReference(endpoint: string, Id: string) {
    try {
      const data = { Id }; // Creating an object with the required property name
      const response = await axiosInstance.delete(endpoint, {
        data: Id,
        // Other Axios configuration options can be passed here
      });
      // const response = await axiosInstance.delete(`${endpoint}/${referenceId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async postIdentity(endpoint: string, data: any) {
    try {
      const response = await identityInstance.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async postIdentityWithHeader(endpoint: string, headers: any, data: any) {
    try {
      const response = await identityInstance.post(endpoint, data, { headers });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async putIdentity(endpoint: string, data: any) {
    try {
      const response = await identityInstance.put(endpoint, data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async postMultipart(endpoint: string, formData: FormData) {
    try {
      const response = await axiosInstance.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for file upload
          //"Authorization": `Bearer `+  getAccessToken() || ""
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async putMultipart(endpoint: string, formData: FormData) {
    try {
      const response = await axiosInstance.put(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for file upload
          //"Authorization": `Bearer `+ getAccessToken() || ""
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
