import React from "react";

interface TechnologiesListProps {
  companyTechnologies: any[];
}

const TechnologiesList: React.FC<TechnologiesListProps> = ({ companyTechnologies }) => {
  return (
    <>
      {companyTechnologies.map((technology: any) => (
        <span key={technology.id} className="technology-pill">
          {technology.name}
        </span>
      ))}
    </>
  );
};

export default TechnologiesList;
