import React from "react";

interface ServicesListProps {
  companyServices: any[];
}

const ServicesList: React.FC<ServicesListProps> = ({ companyServices }) => {
  return (
    <>
      {companyServices.map((service: any) => (
        <span key={service.id} className="service-pill">
          {service.name}
        </span>
      ))}
    </>
  );
};

export default ServicesList;
