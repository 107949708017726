import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import SigninCallback from "./utils/SigninCallback";
import { IAuthService } from "./services/authService";
// import AppContent from '../components/AppContent';
// import { IApiService } from '../services/ApiService';
import { User } from "oidc-client";
// import * as toastr from 'toastr';
// import Buttons from '../components/Buttons';

import Home from "./pages/Home/Home";
import CompanyDetail from "./pages/CompanyDetail/CompanyDetail";
import Search from "./pages/Search/Search";
import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import Header from "./components/Header/Header";
import EditProfile from "./pages/EditProfile/EditProfile";
import Footer from "./components/Footer/Footer";
import UnderConstruction from "./pages/UnderConstruction/UnderConstruction";
import PrivacyPolicy from "./pages/Other/PrivacyPolicy";
import TermsConditions from "./pages/Other/TermConditions";
import Companies from "./pages/Other/Companies";
import Featured from "./pages/Featured/Featured";
import Contact from "./pages/Contact/Contact";

interface IRouter {
  authService: IAuthService;
}

interface IProtectedRoute {
  authService: IAuthService;
  path: string;
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<IProtectedRoute> = ({
  authService,
  path,
  children,
}) => {
  const [user, setUser] = useState<User | null>();
  const navigate = useNavigate();

  useEffect(() => {
    const serviceGetUser = async () => {
      const userResponse = await authService.getUser();
      if (userResponse) {
        console.log("User has been loaded from store.");
        console.log("userResponse router");
        console.log(userResponse);
      } else {
        console.log("You are not logged in.");
        navigate("/");
      }
      setUser(userResponse);
      return userResponse;
    };

    serviceGetUser();
  }, [authService, navigate]);

  const login = () => {
    authService.login();
  };

  const logout = () => {
    authService.logout();
  };

  return user != null ? (
    <Route path={path} element={children}>
      <button
        className="btn btn-dark btn-logout"
        style={{ margin: "10px" }}
        onClick={logout}
      >
        Logout
      </button>
    </Route>
  ) : (
    <button
      className="btn btn-primary btn-login"
      style={{ margin: "10px" }}
      onClick={login}
    >
      Login
    </button>
  );
};

const Router: React.FC<IRouter> = (props) => {
  const location = useLocation();

  // Define a function to check if the current URL matches any of the specified paths
  const shouldRenderTemplate = () => {
    return !location.pathname.startsWith("/signup");
  };

  return (
    <>
      {shouldRenderTemplate() && <Header authService={props.authService} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={<Login authService={props.authService} />}
        />
        <Route
          path="/signin-callback"
          element={<SigninCallback authService={props.authService} />}
        />
        <Route
          path="/company/:id"
          element={<CompanyDetail authService={props.authService} />}
        />
        <Route path="/search/*" element={<Search />} />
        <Route path="/featured" element={<Featured />} />
        <Route path="/contact/*" element={<Contact />} />
        <Route
          path="/signup/*"
          element={<Signup authService={props.authService} />}
        />
        <Route
          path="/edit-profile/*"
          element={<EditProfile authService={props.authService} />}
        />
        <Route path="/under-construction" element={<UnderConstruction />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/companies" element={<Companies />} />
      </Routes>
      {shouldRenderTemplate() && <Footer />}
    </>
  );
};

export default Router;
