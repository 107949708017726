import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/placeholder-logo.png";

enum CompanySize {
  "1-10" = 0,
  "11-50" = 1,
  "51-100" = 2,
  "101-200" = 3,
  "201-500" = 4,
  "501-1000" = 5,
  "1000+" = 6,
}

interface CompanyData {
  id: string;
  name: string;
  rating: number | null;
  profilePictureUrl: string | null;
  location: string | null;
  size: number | null;
  companyReviewes: any[]; // Update this type accordingly
}

interface CompaniesCardProps {
  companyData: CompanyData;
}

function CompaniesCard({ companyData }: CompaniesCardProps) {
  const {
    id,
    name,
    rating,
    profilePictureUrl,
    location,
    size,
    companyReviewes,
  } = companyData;
  // const comanyLogo = profilePictureUrl ? profilePictureUrl : logo;
  const comanyLogo = profilePictureUrl
    ? profilePictureUrl.includes("Uploads")
      ? `${profilePictureUrl}`
      : profilePictureUrl
    : logo;

  return (
    <div className="card company-card h-100 text-center">
      <Link to={"/company/" + id} className="company-logo stretched-link">
        <img src={comanyLogo} alt="" />
      </Link>
      <div className="card-body">
        <h5 className="card-title text-truncate px-3">{name}</h5>
        {/* <p className="card-text text-primary">{rating ? rating : "0"}</p> */}
      </div>
      <div className="card-footer">
        <div className="row row-cols-auto justify-content-center g-3">
          {/* <div className="col">
            {companyReviewes.length}{" "}
            <small className="text-primary d-block">Reviews</small>
          </div> */}
          <div className="col">
            <span className="text-primary d-block">Location</span>
            <small>{location ? location : "-"}</small>
          </div>
          <div className="col">
            <span className="text-primary d-block">Employees</span>
            <small>{size ? CompanySize[size] : "Undisclosed"}</small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompaniesCard;
