import React, { useState, useEffect, useRef } from "react";
import { apiService } from "../services/apiService";
import { useParams, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const Claim = () => {
  const { id } = useParams<{ id: string }>();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState(
    "Company Profile Ownership Verification"
  );
  const [captchaValue, setCaptchaValue] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState<null | "success">(
    null
  );
  const [formSubmission, setFormSubmission] = useState(true);
  const [errors, setErrors] = useState<{
    name?: string;
    email?: string;
    phone?: string;
    captchaValue?: string;
  }>({});
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const isFormValid =
      name.trim() &&
      email.trim() &&
      /\S+@\S+\.\S+/.test(email) &&
      phone.trim() &&
      captchaValue.trim();

    setIsFormValid(Boolean(isFormValid));
  }, [name, email, phone, captchaValue]);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      const formData = {
        Name: name,
        CompanyId: id,
        Email: email,
        PhoneNumber: phone,
        ReasonOfMessage: subject,
        Details: "",
      };

      try {
        setFormSubmission(false);
        setTimeout(async () => {
          const endpoint = `Claim`;
          await apiService
            .post(endpoint, formData)
            .then((response) => {
              if (response) {
                setName("");
                setEmail("");
                setPhone("");
                setSubmissionStatus("success");
              } else {
              }
            })
            .catch((error) => {
              console.error(error);
            });

          setFormSubmission(true);
          toast.success("Request submitted successfully!");
        }, 250); // 1000 milliseconds (1 second)
      } catch (error) {
        // Handle error if needed
        //setSubmissionStatus("success");
        toast.error("Request submission failed!");
        setFormSubmission(true);
      }
    } else {
      // Form is invalid, display errors
      setErrors(validationErrors);
    }
  };

  const validateForm = () => {
    let errors: {
      name?: string;
      email?: string;
      phone?: string;
      captchaValue?: string;
    } = {};

    if (!name.trim()) {
      errors.name = "Name is required";
    }

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format";
    }

    if (!phone.trim()) {
      errors.phone = "Phone is required";
    }

    if (!captchaValue.trim()) {
      errors.captchaValue = "Captcha is required";
    }

    return errors;
  };

  const handleRecaptchaChange = (value: string | null) => {
    if (value === "" || value === null) {
      setCaptchaValue("");
    } else {
      setCaptchaValue(value);
    }
  };
  const handleCloseModal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };

  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <form onSubmit={handleSubmit} className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              Claim company
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={closeButtonRef}
            ></button>
          </div>
          <div className="modal-body">
            {submissionStatus === "success" ? (
              <>
                <p className="text-success">We have received your message.</p>
                <p>We will get in touch with you as soon as possible.</p>
              </>
            ) : (
              <>
                <div className="mb-3">
                  <input
                    type="text"
                    id="name"
                    className={`form-control ${errors.name && "is-invalid"}`}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                  />
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    id="email"
                    className={`form-control ${errors.email && "is-invalid"}`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    id="phone"
                    className={`form-control ${errors.phone && "is-invalid"}`}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone number (with country code)"
                  />
                  {errors.phone && (
                    <div className="invalid-feedback">{errors.phone}</div>
                  )}
                </div>
                <div className="mb-3">
                  <div className={`${errors.captchaValue && "is-invalid"}`}>
                    <ReCAPTCHA
                      sitekey="6LebExcoAAAAAOvnkOfbLtKuZI6FVxWrB8cO2_OP"
                      onChange={handleRecaptchaChange}
                    />
                  </div>
                  {errors.captchaValue && (
                    <div className="invalid-feedback">
                      {errors.captchaValue}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            {submissionStatus === "success" ? (
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            ) : (
              <>
                {formSubmission ? (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={!isFormValid}
                  >
                    Claim
                  </button>
                ) : (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      aria-hidden="true"
                    ></span>
                    <span className="ms-2" role="status">
                      Claim
                    </span>
                  </button>
                )}
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Claim;
