import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { apiService } from "../../../services/apiService";
import Cards from "../../../shared/Cards";
import CompanyResponse from "../../../Models/CompanyResponse";
import SearchState from "../../../Models/SearchState";
import addImage from "../../../assets/img/place-ad-banner07.png";
import PageLoader from "../../../shared/page-loader";
const FeaturedCompanies: React.FC = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [state, setState] = useState<SearchState>({
    companyData: {} as CompanyResponse,
    isLoading: true,
    pageNumber: 1,
    PageSize: 6,
    OrderByColumn: "name",
    OrderBy: "ASC",
  });
  const [moreCompanies, setMoreCompanies] = useState(true);
  const [ownCompany, setOwnCompany] = useState();
  const IsFeatured = true;

  const loadCompaniesData = async (pageNumber: number) => {
    const userCompanyData = JSON.parse(
      localStorage.getItem("userCompany") || "{}"
    );
    setOwnCompany(userCompanyData);

    const endpoint = `Company?IsFeatured=${IsFeatured}&PageNumber=${pageNumber}&PageSize=${
      state.PageSize
    }&OrderByColumn=${state.OrderByColumn}&OrderBy=${
      state.OrderBy
    }&LoggedInCompanyId=${(userCompanyData && userCompanyData.id) || ""}`;
    try {
      let userCompanyIndex = -1;
      const featuredCompanies = await apiService.get(endpoint);
      setState((prevState) => ({
        ...prevState,
        companyData: {
          ...featuredCompanies,
          items:
            pageNumber === 1
              ? featuredCompanies.items
              : [...prevState.companyData.items, ...featuredCompanies.items],
        },
        isLoading: false,
      }));
      setPageLoading(false);
    } catch (error) {
      setTimeout(() => {
        setPageLoading(false);
      }, 1000);
      console.error(error);
    }
  };

  const loadMoreCompanies = () => {
    if (state.pageNumber < state.companyData.totalPages) {
      setState((prevState) => ({
        ...prevState,
        pageNumber: prevState.pageNumber + 1,
        isLoading: true,
      }));
      //loadCompaniesData(state.pageNumber + 1);
    } else {
      setMoreCompanies(false);
    }
  };

  useEffect(() => {
    loadCompaniesData(state.pageNumber);
  }, [state.pageNumber]);
  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <div className="container">
        <h2 className="text-center sectionHeading">
          Explore my top suggestions for you!
        </h2>
        {state.companyData && (
          <div className="row row-cols-2 row-cols-md-3 row-cols-lg-6 g-3">
            {state.companyData?.items
              ? state.companyData.items.map((companyData) => (
                  <div key={companyData.id} className="col">
                    <Cards companyData={companyData} />
                  </div>
                ))
              : !state.isLoading && <p>No companies found</p>}
          </div>
        )}

        <div className="d-grid gap-2 d-flex justify-content-center mt-5">
          {!state.isLoading &&
            state.pageNumber < state.companyData.totalPages && (
              <>
                {moreCompanies ? (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={loadMoreCompanies}
                  >
                    Show More
                  </button>
                ) : null}
              </>
            )}
          {state.isLoading && (
            <button className="btn btn-primary" type="button" disabled>
              <span
                className="spinner-grow spinner-grow-sm"
                aria-hidden="true"
              ></span>
              <span className="ms-2" role="status">
                Loading
              </span>
            </button>
          )}
          <NavLink to="/companies" className="btn btn-outline-primary">
            See all companies
          </NavLink>
        </div>
      </div>
    </>
  );
};
export default FeaturedCompanies;
