import React, { useState } from "react";
import { apiService } from "../../../services/apiService";
import { toast } from "react-toastify";
import "../Header.scss";

interface VerificationProps {
  primaryEmail: string;
}

const Verification: React.FC<VerificationProps> = ({ primaryEmail }) => {
  const [submissionStatus, setSubmissionStatus] = useState<null | "success">(
    null
  );
  const [formSubmission, setFormSubmission] = useState(true);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent the default action

    const formData = {
      userEmail: primaryEmail,
    };

    try {
      setFormSubmission(false);

      const endpoint = `ResendConfirmationEmail?userEmail=${primaryEmail}`;
      setTimeout(async () => {
        try {
          const response = await apiService.putIdentity(endpoint, formData);
          if (response) {
            setSubmissionStatus("success");
            toast.success("Verification email resent successfully!");
          }
        } catch (error) {
          console.error(error);
          toast.error("Failed to resend verification email.");
        } finally {
          setFormSubmission(true);
        }
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.error("Error handling the request.");
      setFormSubmission(true);
    }
  };

  return (
    <>
      <div
        className="alert alert-warning alert-dismissible fade show verification-alert"
        role="alert"
      >
        <div className="container text-center">
          <strong>Verify Your Email:</strong> Your company will be listed once
          email is verified.{" "}
          <button
            onClick={handleSubmit}
            disabled={!formSubmission}
            className="btn btn-link text-info verification-button p-0"
          >
            {formSubmission ? (
              "Resend verification email"
            ) : (
              <>
                <div className="spinner-border text-info" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>{" "}
                Sending
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Verification;
