import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Banner.scss";

function SearchBanner() {
  const videoPath = `./../../assets/videos/jumbotron.mp4`;
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const term = searchParams.get("searchkey") || "";
    setSearchTerm(term);
  }, []);

  const handleSearch = () => {
    const formattedSearchTerm = searchTerm.trim().toLowerCase();
    console.log("Search Term:", formattedSearchTerm);
    navigate(`/search?searchkey=${formattedSearchTerm}`);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const isSearchPage = location.pathname === "/search";

  return (
    <div className="jumbotron text-center">
      <video autoPlay loop muted className="banner-video">
        <source src={videoPath} type="video/mp4" />
      </video>
      <div className="container">
        <h1>
          Hi, I am <span className="text-primary">Alva</span>
          <small className="d-block mb-4">
            I can help you find Pakistani IT companies
          </small>
        </h1>

        <div className="input-group mt-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search with company name, service, or technology"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleSearch}
          >
            GO
          </button>
        </div>

        {/* Conditional link rendering */}
        {isSearchPage && (
          <div className="mt-4">
            <a
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasResponsive"
              aria-controls="offcanvasResponsive"
              className="text-light h6"
            >
              &#9776; Advanced Search
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchBanner;
