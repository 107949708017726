﻿export class Constants {
 
  public static clientId = 'WebClient';

  //production config
  // public static stsAuthority = 'https://Askalva.quantumcph.com';
  // public static clientRoot = 'https://Askalva.quantumcph.com/';
  // public static apiRoot = 'https://identity.quantumcph.com';

  //local config
  public static stsAuthority = process.env.REACT_APP_IDENTITY_URL;
  public static clientRoot = process.env.REACT_APP_CLIENTROOT_URL;
  public static apiRoot = process.env.REACT_APP_IDENTITY_URL;
  
  public static clientScope = 'openid profile CompanyBackendApi.Full IdentityServerApi.Full UserBackendApi.Full';
}